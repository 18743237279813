import { Group } from '@iheartradio/web.accomplice/group';
import { Stack } from '@iheartradio/web.accomplice/stack';
import {
  Button,
  NumberInput,
  Text,
  TextInput,
} from '@iheartradio/web.companion';
import { ACCOUNT_CONSTANTS } from '@iheartradio/web.config';
import { isNotBlank } from '@iheartradio/web.utilities';

import { EmailOptIn } from '~app/components/email-opt-in';
import { GenderRadioGroup } from '~app/components/gender-radio-group';
import { PrivacyTermsOptout } from '~app/components/privacy-terms-optout';
import { useConfig } from '~app/contexts/config';
import {
  BirthYearHint,
  GenderHint,
  ZipCodeHint,
} from '~app/routes/sign-up.email/sign-up-email';
import type { OauthLoginActionErrors } from '~app/utilities/social/types';

export type SocialLoginUserInfoProps = {
  accessToken?: string;
  accessTokenType?: string;
  client_id?: string;
  connectedTitle: string;
  connectedToSocialProvider: boolean;
  errors?: OauthLoginActionErrors | null;
  fpHash: string;
  isSubmitting: boolean;
  oauthUuid?: string;
  redirectUrl?: string | null;
  redirect_uri?: string;
  userName?: string;
};

export const SocialLoginUserInfo = ({
  accessToken,
  accessTokenType,
  client_id,
  connectedTitle,
  connectedToSocialProvider,
  errors,
  fpHash,
  isSubmitting,
  oauthUuid,
  redirectUrl,
  redirect_uri,
  userName,
}: SocialLoginUserInfoProps) => {
  const config = useConfig();

  const zipKeyboard =
    config.account.registration.zipKeyboard ??
    ACCOUNT_CONSTANTS.KEYBOARD_ALPHANUMERIC;

  return (
    <Stack
      css={{
        paddingTop: 0,
        paddingX: '$24',
        paddingBottom: '$24',
      }}
      gap="$32"
    >
      <Stack gap="$8">
        <Text kind="h3">Finish signing up</Text>
        <Text kind="subtitle-4">
          {connectedTitle} Please fill out the rest of the required information
          to sign up.
        </Text>
      </Stack>
      <input name="accessTokenType" type="hidden" value={accessTokenType} />
      <input name="accessToken" type="hidden" value={accessToken} />
      <input name="oauthUuid" type="hidden" value={oauthUuid} />
      <input name="type" type="hidden" value="createUser" />
      {redirectUrl ?
        <input name="redirectUrl" type="hidden" value={redirectUrl} />
      : null}
      {redirect_uri ?
        <input name="redirect_uri" type="hidden" value={redirect_uri} />
      : null}
      {client_id ?
        <input name="client_id" type="hidden" value={client_id} />
      : null}
      <input name="fpHash" type="hidden" value={fpHash} />
      {isNotBlank(userName) ?
        <input name="userName" type="hidden" value={userName} />
      : <TextInput
          autoComplete="username"
          defaultValue={userName}
          disabled={!connectedToSocialProvider}
          label="Email"
          message={errors?.userName}
          name="userName"
          placeholder="Enter email address"
          required
          type="email"
        />
      }
      <Group gap={{ mobile: '$24', large: '$32' }}>
        <NumberInput
          autoComplete="false"
          disabled={!connectedToSocialProvider}
          hint={BirthYearHint}
          id="birthYear"
          inputMode="numeric"
          label="Birth Year"
          message={errors?.birthYear}
          name="birthYear"
          placeholder="Enter birth year"
          required
        />
        <TextInput
          autoComplete="false"
          disabled={!connectedToSocialProvider}
          hint={ZipCodeHint}
          id="zipCode"
          inputMode={
            zipKeyboard === ACCOUNT_CONSTANTS.KEYBOARD_NUMERIC ?
              'numeric'
            : 'text'
          }
          label="Zip Code"
          message={errors?.zipCode}
          name="zipCode"
          placeholder="Enter zip code"
          required
        />
      </Group>
      <GenderRadioGroup
        disabled={!connectedToSocialProvider}
        hint={GenderHint}
        name="gender"
        required={true}
      />
      <EmailOptIn disabled={!connectedToSocialProvider} />
      <PrivacyTermsOptout />
      <Button
        color="red"
        css={{ justifyContent: 'center', width: '100%' }}
        disabled={isSubmitting || !connectedToSocialProvider}
        kind="primary"
        size="large"
        type="submit"
      >
        Sign up for free
      </Button>
    </Stack>
  );
};
